import React, { useState, useEffect } from 'react';
import { AlertTriangle } from 'lucide-react';

const PortraitLock = ({ children }) => {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  const handleOrientationChange = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    handleOrientationChange(); // Initial check

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    // Disable scrolling when in landscape mode
    document.body.style.overflow = isPortrait ? 'auto' : 'hidden';
  }, [isPortrait]);

  if (!isPortrait) {
    return (
      <div className="fixed inset-0 w-screen h-screen flex flex-col items-center justify-center bg-gray-100 px-4 z-50">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
          <div className="mb-6 animate-bounce">
            <AlertTriangle className="mx-auto h-16 w-16 text-yellow-500" />
          </div>
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Rotate Device</h1>
          <h2 className="text-2xl font-semibold text-gray-600 mb-4">Landscape Mode Not Supported</h2>
          <p className="text-gray-500 mb-8">
            Please rotate your device to portrait mode to continue using this app.
          </p>
        </div>
      </div>
    );
  }

  return children;
};

export default PortraitLock;