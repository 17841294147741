import React from 'react';

const AICenter = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      {/* Main Section */}
      <header className="text-center py-10">
        <h1 className="text-3xl font-bold text-[#2E6C83]">Welcome to our <strong>AI Center</strong></h1>
        <p className="text-lg mt-2 text-gray-600">Where You'll Find All our AI Services</p>
      </header>

      {/* AI Services List */}
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl text-center">
        <h2 className="text-2xl font-semibold text-[#2E6C83] mb-4">
          Unfortunately our AI services are currently Not available on Mobile Devices
        </h2>
        <p className="text-gray-500">
          To access our AI services we recommend you to visit our dashboard on a desktop computer.
        </p>
      </div>

      {/* Call to Action Section */}
      <footer className="text-center py-8">
        <p className="text-gray-500 text-sm">Copyright © 2024 Nutriplan. All rights reserved.</p>
        <p className="text-gray-500 text-sm">Nutriplan Developed by Web-Design Artists</p>
      </footer>
    </div>
  );
};

export default AICenter;
