import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import MainSidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import YourPlan from './pages/YourPlan';
import MyHealth from './pages/MyHealth';
import Trackers from './components/Trackers';
import Profile from './components/Profile';
import Onboarding from './components/Onboarding';
import Auth from './components/Auth';
import PrivateRoute from './components/PrivateRoute';
import RightSidebar from "./components/right-sidebar";
import Footer from './components/Footer';
import Error from "./pages/error";
import AICenter from './components/center';
import NutriBot from './components/nutribot';
import Mealplanner from './components/meal-planner';
import NotFound from './pages/notfound';
import PortraitLock from './pages/PortraitLock';

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i;
      setIsDesktop(!mobileRegex.test(userAgent));
    };

    checkDevice();
    window.addEventListener('resize', checkDevice);

    return () => {
      window.removeEventListener('resize', checkDevice);
    };
  }, []);

  return isDesktop;
};

const App = () => {
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isDesktop) {
      window.location.href = 'https://dashboard.nutriplan.seif8911.xyz';
    } else {
      setIsLoading(false);
    }
  }, [isDesktop]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a proper loading component
  }

  if (isDesktop) {
    return null; // Return null while redirecting
  }

  const isAuthRoute = location.pathname === '/login' || location.pathname === '/onboarding';

  return (
    <PortraitLock>
      <div className="flex flex-col min-h-screen">
        {!isAuthRoute && <MainSidebar />}
        <div className={`flex-grow ${!isAuthRoute ? 'md:ml-48' : ''}`}>
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/Error" element={<Error />} />
            <Route path="/" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path="/yourplan" element={
              <PrivateRoute>
                <YourPlan />
              </PrivateRoute>
            } />
            <Route path="/myhealth" element={
              <PrivateRoute>
                <MyHealth />
              </PrivateRoute>
            } />
            <Route path="/trackers" element={
              <PrivateRoute>
                <Trackers />
              </PrivateRoute>
            } />
            <Route path="/profile" element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            } />
            <Route path="/center" element={
              <PrivateRoute>
                <AICenter />
              </PrivateRoute>
            } />
            <Route path="/nutribot" element={
              <PrivateRoute>
                <NutriBot />
              </PrivateRoute>
            } />
            <Route path="/meal-planner" element={
              <PrivateRoute>
                <Mealplanner />
              </PrivateRoute>
            } />
            <Route path="*" element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            } />
          </Routes>
        </div>
        {!isAuthRoute && <RightSidebar />}
        <Footer />
      </div>
    </PortraitLock>
  );
};

export default App;