import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import Joyride, { STATUS } from 'react-joyride';

const MyHealth = () => {
  const [healthData, setHealthData] = useState({
    weight: '',
    height: '',
    steps: '',
    calories: '',
    hydration: '',
    exerciseMinutes: '',
    dailyCalorieGoal: '',
    dailyStepGoal: '',
    dailyHydrationGoal: '',
    dailyExerciseGoal: '',
  });
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [runTour, setRunTour] = useState(false);

  const [tourSteps] = useState([
    {
      target: 'body',
      content: 'Welcome to the My Health tab! Here you can update your health information and set daily goals.',
      placement: 'center',
    },
    {
      target: '#weight',
      content: 'Enter your current weight here.',
    },
    {
      target: '#height',
      content: 'Input your height in centimeters.',
    },
    {
      target: '#steps',
      content: 'Record your daily step count.',
    },
    {
      target: '#calories',
      content: 'Track the calories you\'ve burned today.',
    },
    {
      target: '#hydration',
      content: 'Log how many glasses of water you\'ve consumed.',
    },
    {
      target: '#exerciseMinutes',
      content: 'Enter the number of minutes you\'ve exercised today.',
    },
    {
      target: '#dailyCalorieGoal',
      content: 'Set your daily calorie burn goal.',
    },
    {
      target: '#dailyStepGoal',
      content: 'Establish your daily step target.',
    },
    {
      target: '#dailyHydrationGoal',
      content: 'Define how many glasses of water you aim to drink daily.',
    },
    {
      target: '#dailyExerciseGoal',
      content: 'Set your daily exercise duration goal in minutes.',
    },
  ]);

  useEffect(() => {
    const fetchHealthData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const healthDoc = await db.collection('health').doc(user.uid).get();
          if (healthDoc.exists) {
            setHealthData(healthDoc.data());
          }
        } catch (error) {
          console.error('Error fetching health data:', error);
          setErrorMessage('Failed to fetch health data. Please try again later.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setErrorMessage('User not authenticated. Please log in.');
      }
    };

    fetchHealthData();

    const hasSeenTour = localStorage.getItem('hasSeenMyHealthTour');
    if (!hasSeenTour) {
      setRunTour(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      setErrorMessage('User not authenticated. Please log in.');
      return;
    }

    try {
      await db.collection('health').doc(user.uid).set(healthData, { merge: true });
      alert('Health data saved successfully!');
    } catch (error) {
      console.error('Error saving health data:', error);
      setErrorMessage('Failed to save health data. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const numValue = parseFloat(value);
    
    // Validate input
    if (id === 'weight' && (numValue < 0 || numValue > 500)) return;
    if (id === 'height' && (numValue < 0 || numValue > 300)) return;
    if (id === 'steps' && (numValue < 0 || numValue > 20000)) return;
    if (id === 'calories' && (numValue < 0 || numValue > 5000)) return;
    if (id === 'hydration' && (numValue < 0 || numValue > 50)) return;
    if (id === 'exerciseMinutes' && (numValue < 0 || numValue > 960)) return;
    if (id === 'dailyCalorieGoal' && (numValue < 0 || numValue > 5000)) return;
    if (id === 'dailyStepGoal' && (numValue < 0 || numValue > 20000)) return;
    if (id === 'dailyHydrationGoal' && (numValue < 0 || numValue > 50)) return;
    if (id === 'dailyExerciseGoal' && (numValue < 0 || numValue > 960)) return;
    if (['steps', 'calories', 'hydration', 'exerciseMinutes'].includes(id) && numValue < 0) return;
  
    setHealthData(prevData => ({
      ...prevData,
      [id]: value
    }));
  
    // Save data as user types
    saveHealthData({ ...healthData, [id]: value });
  };
  
  const saveHealthData = async (data) => {
    const user = auth.currentUser;
    if (user) {
      try {
        await db.collection('health').doc(user.uid).set(data, { merge: true });
      } catch (error) {
        console.error('Error saving health data:', error);
        setErrorMessage('Failed to save health data. Please try again.');
      }
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      localStorage.setItem('hasSeenMyHealthTour', 'true');
    }
  };

  if (loading) return <div className="text-center justify-content-center align-text-center mt-8">Loading...</div>;

  if (errorMessage) return <div className="text-center mt-8 text-red-500">{errorMessage}</div>;

  return (
    <div className="flex flex-col min-h-screen bg-gray-200">
      <div className="flex-grow flex items-center justify-center py-8 px-4">
        <div className="bg-gray-100 p-6 rounded shadow-lg w-full max-w-md">
          <h1 className="text-2xl font-bold text-[#2E6C83] mb-6">My Health</h1>
          <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="weight" className="block text-sm mb-1 text-[#2E6C83]">Weight (kg)</label>
              <input
                type="number"
                id="weight"
                placeholder="Weight"
                value={healthData.weight}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                max="500"
                required
              />
            </div>
            <div>
              <label htmlFor="height" className="block text-sm mb-1 text-[#2E6C83]">Height (cm)</label>
              <input
                type="number"
                id="height"
                placeholder="Height"
                value={healthData.height}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                max="300"
                required
              />
            </div>
            <div>
              <label htmlFor="steps" className="block text-sm mb-1 text-[#2E6C83]">Steps</label>
              <input
                type="number"
                id="steps"
                placeholder="Steps"
                value={healthData.steps}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                required
              />
            </div>
            <div>
              <label htmlFor="calories" className="block text-sm mb-1 text-[#2E6C83]">Calories intake</label>
              <input
                type="number"
                id="calories"
                placeholder="Calories intake"
                value={healthData.calories}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                required
              />
            </div>
            <div>
              <label htmlFor="hydration" className="block text-sm mb-1 text-[#2E6C83]">Hydration (glasses)</label>
              <input
                type="number"
                id="hydration"
                placeholder="Hydration"
                value={healthData.hydration}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                max="50"
                required
              />
            </div>
            <div>
              <label htmlFor="exerciseMinutes" className="block text-sm mb-1 text-[#2E6C83]">Exercise Minutes</label>
              <input
                type="number"
                id="exerciseMinutes"
                placeholder="Exercise Minutes"
                value={healthData.exerciseMinutes}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                required
              />
            </div>
          </div>

          <h2 className="text-lg mb-2 text-[#2E6C83] mt-6">Set Your Daily Goals:</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="dailyCalorieGoal" className="block text-sm mb-1 text-[#2E6C83]">Daily Calorie Goal</label>
              <input
                type="number"
                id="dailyCalorieGoal"
                placeholder="Daily Calorie Goal"
                value={healthData.dailyCalorieGoal}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                required
              />
            </div>
            <div>
              <label htmlFor="dailyStepGoal" className="block text-sm mb-1 text-[#2E6C83]">Daily Steps Goal</label>
              <input
                type="number"
                id="dailyStepGoal"
                placeholder="Daily Steps Goal"
                value={healthData.dailyStepGoal}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                required
              />
            </div>
            <div>
              <label htmlFor="dailyHydrationGoal" className="block text-sm mb-1 text-[#2E6C83]">Daily Hydration Goal (glasses)</label>
              <input
                type="number"
                id="dailyHydrationGoal"
                placeholder="Daily Hydration Goal"
                value={healthData.dailyHydrationGoal}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                max="50"
                required
              />
            </div>
            <div>
              <label htmlFor="dailyExerciseGoal" className="block text-sm mb-1 text-[#2E6C83]">Daily Exercise Goal (minutes)</label>
              <input
                type="number"
                id="dailyExerciseGoal"
                placeholder="Daily Exercise Goal"
                value={healthData.dailyExerciseGoal}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                min="0"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-[#2E6C83] text-white p-2 rounded w-full mt-6 hover:bg-[#1E5C73] transition-colors"
          >
            Save Health Data
          </button>
        </form>
        {runTour && (
          <Joyride
            steps={tourSteps}
            run={runTour}
            callback={handleJoyrideCallback}
            continuous
            showProgress
            showSkipButton
            styles={{
              options: {
                primaryColor: '#2E6C83',
                textColor: '#000',
                arrowColor: '#2E6C83',
                width: 400,
                zIndex: 1000,
              },
            }}
          />
        )}
        </div>
      </div>
    </div>
  );
};

export default MyHealth;
