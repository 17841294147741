import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const NutriBot = () => {
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (isMobile) {
      navigate('/');
    } else {
      setShowWarning(true);
    }
  }, [navigate]);

  if (isMobile) {
    return null; // or a custom message if you prefer
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      {showWarning && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
          <p>Warning: NutriBot is still in development and may occasionally generate false information. This is not a replacement for doctors or nutritionists. If you're feeling unwell, seek medical help.</p>
        </div>
      )}
      <header className="text-center py-10">
        <h1 className="text-3xl font-bold text-[#2E6C83]">Chat with our <strong>NutriBot</strong></h1>
        <p className="text-lg mt-2 text-gray-600">Optionally, you can use our AI Meal Planner <a href="http://demo1.seif8911.xyz" className="text-[#2E6C83] hover:underline">Here</a></p>
      </header>
      <section className="w-full max-w-5xl bg-white shadow-lg rounded-lg p-6">
        <iframe
          id="nutribot-frame"
          src="https://nutribot.seif8911.xyz"
          frameBorder="0"
          width="100%"
          height="500px"
          title="NutriBot AI Assistant"
          className="rounded-lg"
        ></iframe>
      </section>
    </div>
  );
};

export default NutriBot;